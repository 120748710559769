import React, { useState } from "react";

import { API } from "aws-amplify";

import "./ChatV2.css";
import ChatMessage from "./ChatMessage";
import { Button } from "react-bootstrap";
import AudioStream from "../AudioStream";

const ChatV2 = () => {
	const initialData = {
		message: "",
		token: "token3",
		thread_uuid: "new",
		voice: "male_1",
		model: null,
		skip_audio: "yes",
		catalog_item_id: 0,
	};
	const [data, setData] = useState(initialData);
	const [loading, setLoading] = useState(false);
	const [loadingQuestion, setLoadingQuestion] = useState(false);
	const [threads, setThreads] = useState([]);
	const [messages, setMessages] = useState([]);
	const [mostCommonQuestions, setMostCommonQuestions] = useState([]);
	const [assistantId, setAssistantId] = useState(null);
	const [alreadySetAssistant, setAlreadySetAssistant] = useState(false);

	const poolForAnswer = async (thread_uuid, message_uuid) => {
		const fetchAnswer = async (thread_uuid, message_uuid) => {
			return new Promise((resolve, reject) => {
				const intervalId = setInterval(async () => {
					const response = await API.get("dimo", `/chat-v2/threads/${thread_uuid}/messages/${message_uuid}`, {
						headers: {
							"x-user-token": data.token,
						},
					});
					if (response.data?.answer?.uuid) {
						clearInterval(intervalId);
						resolve(response);
					}
				}, 2000);
			});
		};

		try {
			return await fetchAnswer(thread_uuid, message_uuid);
		} catch (error) {
			console.error(error);
		}
	};

	const resendMessage = async () => {
		const response = await API.get("dimo", `/chat-v2/threads/${data.thread_uuid}/retry`, {
			headers: {
				"x-user-token": data.token,
			},
		});
		console.log("retry -> ", response)
	};

	const registerAudience = async () => {
		const response = await API.post("dimo", `/chat-v2/audience`, {
			headers: {
				"x-user-token": data.token,
			},
			body: {
                message_uuid: messages[messages.length - 1].uuid,
            },
		});
		console.log("response -> ", response)
	};

	const handleChange = (event) => {
		const target = event.target;
		const name = target.name;

		let value = target.value;

		if (target.type === "checkbox") {
			value = target.checked;
		}

		setData({
			...data,
			[name]: value,
		});
	};
	async function forceSqs() {
		await API.get("dimo", `/chat-v2/test/threads/process-sqs-message`, {
			headers: {
				"x-user-token": data.token,
			},
		});
		alert("Mensagens processadas");
	}

	const sendQuestion = async () => {
		if (data.message.trim().length === 0) {
			return;
		}
		setLoadingQuestion(true);
		try {
			let response = null;

			let body = {
				question: data.message,
				voice: data.voice,
			};

			assistantId && (body.assitant_id = assistantId);
			if (data.thread_uuid === "new") {
				setMostCommonQuestions([]);
				setAssistantId(null);
				response = await API.post("dimo", `/chat-v2/threads/initialize`, {
					headers: {
						"x-user-token": data.token,
					},
					body,
				});
			} else {
				body['thread_uuid'] = data.thread_uuid;
				response = await API.post("dimo", `/chat-v2/threads/${data.thread_uuid}/messages/stream`, {
					headers: {
						"x-user-token": data.token,
					},
					body,
				});
			}

			if (response.success) {
				let thread_uuid = data.thread_uuid;
				if (thread_uuid === "new") {
					setMostCommonQuestions([]);
					setAssistantId(null);
					thread_uuid = response.data.question.dimo_thread_uuid;
					setThreads([...threads, response.data.thread]);
					setData({
						...data,
						thread_uuid,
					});
				}
				// const isProduction = process.env.NODE_ENV === "production";
				// if (!isProduction) {
				// 	await API.get("dimo", `/chat-v2/test/threads/process-sqs-message`, {
				// 		headers: {
				// 			"x-user-token": data.token,
				// 		},
				// 	});
				// }
				response = await poolForAnswer(thread_uuid, response.data.question.uuid);
				if (response.success) {
					loadMessages(response.data.question.dimo_thread_uuid);
				} else {
					console.log(response);
				}
			} else {
				alert("ocorreu um erro enviando mensagem");
				console.log(response);
			}
		} catch (e) {
			alert("ocorreu um erro enviando mensagem\nProvavelmente a opebAi ainda ta processando a messagem\nCarrega os chats de novo que já deve ter a resposta");
			console.log(e);
		} finally {
			setLoadingQuestion(false);
		}
	};

	const message = React.createRef();

	const loadChats = async () => {
		setLoading(true);
		setThreads([]);
		try {
			const response = await API.get("dimo", "/chat-v2/threads", {
				headers: {
					"x-user-token": data.token,
				},
			});
			if (response.success) {
				setThreads(response.data.threads);
				if (response.data.threads.length > 0) {
					setData({
						...data,
						thread_uuid: response.data.threads[0].uuid,
					});
					loadMessages(response.data.threads[0].uuid);
				}
			} else {
				alert("ocorreu um erro carregando os chats");
				console.log(response);
			}
		} catch (e) {
			alert("ocorreu um erro carregando os chats");
			console.log(e);
		} finally {
			setLoading(false);
		}
	};

	const handleSelectThread = (event) => {
		const value = event.target.value;

		setData({
			...data,
			thread_uuid: value,
		});

		loadMessages(value);
	};

	const handleClickCommon = async (question) =>  {
		data.message = question;
		sendQuestion();
	}

	const loadSpecializedThread = async () => {
		const response = await API.post("dimo", "/chat-v2/threads/create-thread", {
			headers: {
				"x-user-token": data.token,
			},
			body: {
				catalog_item_id: data.catalog_item_id,
			},
		})

		if (response.success) {
			setData({
				...data,
				thread_uuid: response.data.thread.uuid,
			});

			setMostCommonQuestions(response.data.suggested_questions);
		} else {
			alert(response.errors[0].value)
		}

	};

	const loadMessages = async (thread_uuid) => {
		setLoading(true);

		if (thread_uuid === "new") {
			setLoading(false);
			setMessages([]);
			setMostCommonQuestions([]);
			setAssistantId(null);
			return;
		}

		try {
			const response = await API.get("dimo", `/chat-v2/threads/${thread_uuid}/messages?order=ASC`, {
				headers: {
					"x-user-token": data.token,
				},
				// body: {}
			});
			if (response.success) {
				response.data.messages.map((message) => {
					if (String(message.message).includes("assistant_id")) {
						if (!alreadySetAssistant) {
							setAlreadySetAssistant(true);
							const assistant = JSON.parse(message.message);
							setAssistantId(assistant.assistant_id);
							// response.data.messages = ["Encontrei um assistente especializado neste conteudo que voce buscou, clique em uma das perguntas mais comuns abaixo para obter a resposta ou faça uma nova pergunta"];
						}
					}
					return message;
				});
				
				// if (window.location.pathname === "/teste-interface-v2") {
				// 	await API.post("dimo", "/audience/chat-v2", {
				// 		headers: {
                //             "x-user-token": data.token,
                //         },
                //         body: {
                //             message_uuid: response.data.messages.at(-1).uuid,
                //         },
				// 	})
				// }
				setMessages(response.data.messages);
			} else {
				alert("ocorreu um erro carregando o chat");
				console.log(response);
			}
		} catch (e) {
			alert("ocorreu um erro carregando o chat");
			console.log(e);
		} finally {
			setLoading(false);
		}
		setData((oldData) => {
			return {
				...oldData,
				message: "",
			};
		});
	};

	return (
		<div id="chat-container-v2">
			<div id="chat-title">
				<h1>Dimo.ai - V2</h1>
				<div id="form-section">
					<h2>Token</h2>
					<p>o token é da tabela auth_token</p>
					<input id="token" name="token" className="form-control" placeholder="user token..." type="text" onChange={handleChange} value={data.token} disabled={loading} style={{ marginBottom: "10px" }} />
					<Button onClick={loadChats} disabled={loading}>
						Carregar
					</Button>
				</div>
				<div id="form-section">
					<h2>Produto</h2>
					<p>catalog_item_id de um produto com assistente especializado</p>
					<input id="catalog_item_id" name="catalog_item_id" className="form-control" placeholder="catalog item id" type="text" onChange={handleChange} value={data.catalog_item_id} disabled={loading} style={{ marginBottom: "10px" }} />
					<Button onClick={loadSpecializedThread} disabled={loading}>
						Carregar
					</Button>
				</div>
				<h2 style={{ marginTop: "20px" }}>Chat:</h2>
				<select id="thread_uuid" name="thread_uuid" className="form-control" onChange={handleSelectThread} value={data.thread_uuid} disabled={loading} style={{ marginBottom: "10px", width: "300px" }}>
					{threads.map((thread, idx) => (
						<option key={idx} value={thread.uuid}>
							{thread.title}
						</option>
					))}
					<option value="new">Criar novo chat ...</option>
				</select>
			</div>
			<div id="chat-content">
				<div id="chat-data" style={{ flex: 1, overflowY: "auto", padding: "10px" }}>
				{mostCommonQuestions.length > 0 && (
						<div>
							<h2>Perguntas mais comuns:</h2>
							<div>
								{mostCommonQuestions.map((question, idx) => {
									return <Button disabled={loading} onClick={() => handleClickCommon(question.question)} style={{margin: '2px'}} key={idx}>{question.question}</Button>
								})}
							</div>
						</div>
					)}
					{messages.map((message, idx) => (
						<ChatMessage key={idx} message={message} />
					))}
					{loadingQuestion && "Aguardando resposta..."}
				</div>
				<div id="chat-message-bar" style={{ padding: "10px" }}>
					<textarea id="message" name="message" ref={message} className="form-control" placeholder="Faça uma pergunta..." type="text" onChange={handleChange} value={data.message} rows={4} cols={40} disabled={loading} style={{ marginBottom: "10px" }}></textarea>
					<div>
						<Button style={{ marginRight: '8px' }} onClick={sendQuestion} disabled={loadingQuestion}>
							Enviar
						</Button>
						{/* <Button style={{ marginRight: '8px' }} onClick={registerAudience} disabled={loading}>
							audience
						</Button> */}
						{/* <Button onClick={resendMessage} disabled={loading}>
							Reenviar
						</Button> */}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ChatV2;
